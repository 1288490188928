import { useEffect, useState } from 'react';
import {
  BreadcrumbItem,
  BreadcrumbsBar,
  Button,
  Chips,
  Dialog,
  Divider,
  IconButton,
  SideBarModal,
  Skeleton,
  Text
} from '@anatoscope/circlestorybook';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { Country, DentalNotation, DentistRole, Language } from '../../enum/user';
import { feedbackActions } from '../../store/feedback/feedback.reducer';
import { Establishment } from '../../models/establishment';
import styles from './user-details.module.scss';
import { formatDate, getMessageError, isDentistRole, isLaboratory } from '../../utils/utils';
import { deactivateUser, resetPasswordUser } from '../../services/users.services';
import {
  fetchUserByEmailAsync,
  patchUserAsync,
  usersActions
} from '../../store/users/users.reducers';
import {
  isConnectedUserAdminSelector,
  isUserLoadingSelector,
  userSelector
} from '../../store/users/users.selectors';
import CustomizationForm from '../../pages/private/users-page/detail-user-page/user-forms/CustomizationForm';
import { UserInfo } from '../../models/user';
import IdentificationForm from '../../pages/private/users-page/detail-user-page/user-forms/IdentificationForm';
import EstablishmentForm from '../../pages/private/users-page/detail-user-page/user-forms/EstablishmentForm';
import { EstablishmentPayload } from '../../enum/establishment';
import { ColorPropsEnum } from '../../enum/color.enum';
import { ToastType } from '../../enum/feedback';

type Props = {
  isReadOnly: boolean;
  email: string;
};

const UserDetails = ({ isReadOnly, email }: Props) => {
  const { t } = useTranslation(['user']);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchUserByEmailAsync(email))
      .unwrap()
      .catch((error) => {
        dispatch(
          feedbackActions.setToast({ message: getMessageError(error), type: ToastType.DANGER })
        );
      });
  }, []);

  const user = useAppSelector(userSelector);
  const isLoading = useAppSelector(isUserLoadingSelector);
  const isConnectedUserAdmin = useAppSelector(isConnectedUserAdminSelector);
  const [isOpenedCustomization, setIsOpenedCustomization] = useState(false);
  const [isOpenedIdentification, setIsOpenedIdentification] = useState(false);
  const [isOpenedLaboratory, setIsOpenedLaboratory] = useState(false);
  const [isOpenedClinic, setIsOpenedClinic] = useState(false);
  const [isOpenedDesignCenter, setIsOpenedDesignCenter] = useState(false);
  const [isDialogDeactivateOpened, setIsDialogDeactivateOpened] = useState<boolean>(false);
  const [isDialogResetPasswordOpened, setIsDialogResetPasswordOpened] = useState<boolean>(false);
  const [isDialogAuthorizeUserOpened, setIsDialogAuthorizeUserOpened] = useState<boolean>(false);
  const [isAuthorizingLoading, setIsAuthorizingLoading] = useState(false);

  let dentalNotation = t('user.unknown');
  if (user?.dentalNotation && Object.values(DentalNotation).includes(user.dentalNotation)) {
    dentalNotation =
      t(`userForm.${user.dentalNotation.toLowerCase()}.label`) +
      ' (' +
      t(`userForm.${user.dentalNotation.toLowerCase()}.helperText`) +
      ')';
  }

  /**
   * Interface to define a generic object with React state.
   * When we call displayEstablishment, we know about the establishment type we want to display.
   * An establishment need a sidebar to edit it, and we need to pass to this generic displayEstablishment,
   * the establishment sidebar states (open or close). So we pass the concern React state to avoid more useless
   * calculation.
   */
  interface StateEditEstablishment {
    setState: (state: boolean) => void;
    getState: boolean;
  }

  const displayEstablishment = (
    establishment: Establishment,
    establishmentApiKeyBack: string,
    stateSideBar: StateEditEstablishment,
    allowEdit: boolean = true
  ) => {
    const establishmentTranslationKey: string =
      user?.role &&
      establishment?.type &&
      isDentistRole(user.role) &&
      isLaboratory(establishment.type)
        ? `${DentistRole.DENTIST}_${establishment.type}`
        : (establishment.type as string);

    return (
      <>
        <section className={styles['user-detail__content__section']}>
          <header className={styles['user-detail__content__section__header--name']}>
            <div className={styles['user-detail__content__section__header__name']}>
              <Text
                className={styles['user-detail__content__section__header__title']}
                size="s"
                type="title"
                label={t(`userForm.${establishmentTranslationKey}`)}></Text>
              {isReadOnly && user && allowEdit && (
                <IconButton
                  color={ColorPropsEnum.PRIMARY}
                  iconSize="sm"
                  faIconClass="fa-pen-to-square"
                  onClick={() => stateSideBar.setState(true)}
                  radius="full"
                />
              )}
            </div>
            {isReadOnly &&
              user?.role &&
              establishment.type &&
              isDentistRole(user?.role) &&
              isLaboratory(establishment.type) && (
                <div className={styles['user-detail__content__section__actions']}>
                  <Button
                    className={styles['user-detail__content__section__actions__button']}
                    label={t('datagrid.setAuthorizationOrder.action')}
                    onClick={() => setIsDialogAuthorizeUserOpened(true)}
                    category="primary"
                    isDisabled={user.isAllowedToOrder || user.deletedAt !== undefined}
                    size="s"
                  />

                  <Dialog
                    title={t('datagrid.setAuthorizationOrder.dialog.title', {
                      user: `${user?.firstName} ${user?.lastName}`
                    })}
                    text={t('datagrid.setAuthorizationOrder.dialog.text')}
                    isOpened={isDialogAuthorizeUserOpened}
                    cancelButtonLabel={t('action.cancel', { ns: 'common' })}
                    confirmButtonLabel={t('datagrid.setAuthorizationOrder.dialog.action')}
                    isLoading={isAuthorizingLoading}
                    onCancel={() => setIsDialogAuthorizeUserOpened(false)}
                    onConfirm={allowUserToOrder}
                  />
                </div>
              )}
          </header>
          {isLoading ? (
            <>
              <Skeleton type="text" className="skeleton" />
              <Skeleton type="text" className="skeleton" />
              <Skeleton type="text" className="skeleton" />
              <Skeleton type="text" />
            </>
          ) : (
            <>
              <Text label={establishment.name}></Text>
              {establishment.address && (
                <>
                  <Text label={establishment.address.address}></Text>
                  {establishment.address.additionalAddress && (
                    <Text label={establishment.address.additionalAddress}></Text>
                  )}
                  <div className={styles['user-detail__content__section__main__item']}>
                    <Text
                      label={
                        establishment.address.zipCode +
                        ' ' +
                        establishment.address.city +
                        ' ' +
                        (establishment.address.region ? establishment.address.region : '')
                      }></Text>
                    {Object.values(Country).includes(establishment.address.country as Country) && (
                      <Text
                        label={t(`countries.${establishment?.address?.country?.toLowerCase()}`, {
                          ns: 'common'
                        })}></Text>
                    )}
                  </div>
                </>
              )}
              {establishmentApiKeyBack === EstablishmentPayload.LABORATORY && (
                <div>
                  <Text color={ColorPropsEnum.GREY} label={t('userForm.userAllowedToOrder')}></Text>
                  <Chips
                    firstLabel={t(user?.isAllowedToOrder ? 'yes' : 'no', {
                      ns: 'common'
                    })}
                    color={user?.isAllowedToOrder ? ColorPropsEnum.SUCCESS : ColorPropsEnum.WARNING}
                  />
                </div>
              )}
            </>
          )}
          <SideBarModal
            title={t(`userForm.${establishmentTranslationKey}`)}
            isOpened={stateSideBar.getState}
            onClose={() => stateSideBar.setState(false)}>
            <EstablishmentForm
              establishment={establishment}
              establishmentApiKeyBack={establishmentApiKeyBack}
              saveCallback={handleSubmit}
              closeSideNavCallback={stateSideBar.setState}
            />
          </SideBarModal>
        </section>
        <Divider />
      </>
    );
  };

  const handleSubmit = (userParam: Partial<UserInfo>, email: string) => {
    dispatch(patchUserAsync({ ...userParam, email }))
      .unwrap()
      .then(() => {
        dispatch(
          feedbackActions.setToast({
            message: t('userForm.userUpdated'),
            type: ToastType.SUCCESS
          })
        );
      })
      .catch((error) =>
        dispatch(
          feedbackActions.setToast({ message: getMessageError(error), type: ToastType.DANGER })
        )
      );
  };

  const deactivate = async () => {
    setIsDialogDeactivateOpened(false);
    if (user) {
      deactivateUser(user)
        .then(() => {
          // KISS, only seconds must be different between back and front deleteAt. The data will be updated when user
          // will reload. It's avoid a new call just for a date.
          dispatch(
            usersActions.setUser({
              ...user,
              deletedAt: formatDate(new Date())
            })
          );
          dispatch(
            feedbackActions.setToast({
              message: t('datagrid.deactivate.deactivated'),
              type: ToastType.DANGER
            })
          );
        })
        .catch((error) =>
          dispatch(
            feedbackActions.setToast({ message: getMessageError(error), type: ToastType.DANGER })
          )
        );
    }
  };

  const resetUserPassword = async () => {
    setIsDialogResetPasswordOpened(false);
    if (user) {
      resetPasswordUser(user)
        .then(() => {
          dispatch(
            feedbackActions.setToast({
              message: t('userForm.resetPassword.success'),
              type: ToastType.SUCCESS
            })
          );
        })
        .catch((error) =>
          dispatch(
            feedbackActions.setToast({ message: getMessageError(error), type: ToastType.DANGER })
          )
        );
    }
  };

  const allowUserToOrder = async () => {
    if (user) {
      setIsAuthorizingLoading(true);
      dispatch(patchUserAsync({ isAllowedToOrder: true, email: user.email }))
        .then(() => {
          dispatch(usersActions.setUser({ ...user, ...{ isAllowedToOrder: true } }));

          dispatch(
            feedbackActions.setToast({
              message: t('datagrid.setAuthorizationOrder.success'),
              type: ToastType.SUCCESS
            })
          );
        })
        .catch((error) =>
          dispatch(
            feedbackActions.setToast({ message: getMessageError(error), type: ToastType.DANGER })
          )
        )
        .finally(() => {
          setIsAuthorizingLoading(false);
          setIsDialogAuthorizeUserOpened(false);
        });
    }
  };

  const tagContent =
    user?.tags && user.tags.length > 0
      ? user.tags.map((tag) => (
          <Chips
            key={tag}
            firstLabel={tag}
            className={styles['user-detail__content__section__main__item__chips']}
          />
        ))
      : '-';

  return (
    user && (
      <>
        {isReadOnly && (
          <BreadcrumbsBar className={styles['user-detail__breadcrumb']}>
            <BreadcrumbItem link="/users" text={t('tabs.users')} />
            <BreadcrumbItem
              link={`/users/${user?.email}/detail`}
              text={t('userForm.titleDetail')}
            />
          </BreadcrumbsBar>
        )}
        <div className={styles['user-detail__content']}>
          <section className={styles['user-detail__content__section']}>
            <header className={styles['user-detail__content__section__header--name']}>
              <div className={styles['user-detail__content__section__header__name-with-status']}>
                {user && (
                  <>
                    <Text
                      className={styles['user-detail__content__section__header__title']}
                      label={`${user.firstName} ${user.lastName}`}
                      size="s"
                      type="title"></Text>
                    <div className={styles['user-detail__content__section__header__status']}>
                      {isLoading ? (
                        <Skeleton type="text" />
                      ) : (
                        <Chips
                          firstLabel={t(user.deletedAt ? 'inactive' : 'active', {
                            ns: 'common'
                          })}
                          secondLabel={user.deletedAt ? formatDate(new Date(user.deletedAt)) : ''}
                          color={user.deletedAt ? ColorPropsEnum.DANGER : ColorPropsEnum.SUCCESS}
                        />
                      )}
                    </div>
                    {isReadOnly && (
                      <IconButton
                        color={ColorPropsEnum.PRIMARY}
                        iconSize="sm"
                        faIconClass="fa-pen-to-square"
                        onClick={() => setIsOpenedIdentification(true)}
                        radius="full"
                      />
                    )}
                  </>
                )}
              </div>
              <div className={styles['user-detail__content__section__actions']}>
                {!user?.deletedAt && (
                  <>
                    {isReadOnly && (
                      <Button
                        className={styles['user-detail__content__section__actions__button']}
                        label={t('action.deactivate', { ns: 'common' })}
                        onClick={() => setIsDialogDeactivateOpened(true)}
                        category="secondary"
                        size="s"
                      />
                    )}
                    <Button
                      className={styles['user-detail__content__section__actions__button']}
                      label={
                        isReadOnly
                          ? t('userForm.resetPassword.action')
                          : t('userForm.resetMyPassword')
                      }
                      onClick={() => setIsDialogResetPasswordOpened(true)}
                      category="primary"
                      size="s"
                    />
                  </>
                )}

                <Dialog
                  title={t('datagrid.deactivate.dialog.title', {
                    user: `${user?.firstName} ${user?.lastName}`
                  })}
                  text={t('datagrid.deactivate.dialog.text')}
                  isOpened={isDialogDeactivateOpened}
                  cancelButtonLabel={t('action.cancel', { ns: 'common' })}
                  confirmButtonLabel={t('action.deactivate', { ns: 'common' })}
                  onCancel={() => setIsDialogDeactivateOpened(false)}
                  onConfirm={deactivate}
                />
                <Dialog
                  title={t('userForm.resetPassword.dialog.title', {
                    user: `${user?.firstName} ${user?.lastName}`
                  })}
                  text={t('userForm.resetPassword.dialog.text', { email: user?.email })}
                  isOpened={isDialogResetPasswordOpened}
                  cancelButtonLabel={t('action.cancel', { ns: 'common' })}
                  confirmButtonLabel={t('userForm.resetPassword.dialog.action')}
                  onCancel={() => setIsDialogResetPasswordOpened(false)}
                  onConfirm={resetUserPassword}
                />
              </div>
            </header>
            <div className={styles['user-detail__content__section__main']}>
              <div>
                <div className={styles['user-detail__content__section__main__item']}>
                  <Text color={ColorPropsEnum.GREY} label={t('userForm.email')}></Text>
                  {isLoading ? <Skeleton type="text" /> : <Text label={user?.email || ''}></Text>}
                </div>
                {!isLoading && user?.phoneNumber && (
                  <div className={styles['user-detail__content__section__main__item']}>
                    <Text color={ColorPropsEnum.GREY} label={t('userForm.phone')}></Text>
                    {isLoading ? <Skeleton type="text" /> : <Text label={user?.phoneNumber}></Text>}
                  </div>
                )}
                <div className={styles['user-detail__content__section__main__item']}>
                  <Text color={ColorPropsEnum.GREY} label={t('userForm.role')}></Text>
                  {isLoading ? (
                    <Skeleton type="text" />
                  ) : (
                    <Text label={t(`roles.${user?.role}`)}></Text>
                  )}
                </div>
                <div>
                  {user?.role && isDentistRole(user?.role) && (
                    <div className={styles['user-detail__content__section__main__item']}>
                      <Text color={ColorPropsEnum.GREY} label={t('userForm.tags')}></Text>
                      {isLoading ? <Skeleton type="text" /> : tagContent}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <SideBarModal
              title={t('userForm.identificationGroup')}
              isOpened={isOpenedIdentification}
              onClose={() => setIsOpenedIdentification(false)}>
              <IdentificationForm
                saveCallback={handleSubmit}
                closeSideNavCallback={setIsOpenedIdentification}
              />
            </SideBarModal>
          </section>
          <Divider />
          {!isLoading && user?.isAllowedToOrder && (
            <>
              <section className={styles['user-detail__content__section']}>
                <header className={styles['user-detail__content__section__header']}>
                  <Text
                    label={t('userForm.statistics')}
                    size="s"
                    className={styles['user-detail__content__section__header__title']}
                    type="title"
                  />
                </header>
                <div>
                  <div>
                    <div>
                      <Text color={ColorPropsEnum.GREY} label={t('userForm.ordersNumber')}></Text>
                      <Text label="15"></Text>
                    </div>
                  </div>
                </div>
              </section>
              <Divider />
            </>
          )}
          {!isLoading &&
            user?.clinic &&
            Object.keys(user?.clinic).length > 0 &&
            displayEstablishment(user.clinic, EstablishmentPayload.CLINIC, {
              setState: setIsOpenedClinic,
              getState: isOpenedClinic
            })}
          {!isLoading &&
            user?.laboratory &&
            Object.keys(user?.laboratory).length > 0 &&
            displayEstablishment(
              user.laboratory,
              EstablishmentPayload.LABORATORY,
              {
                setState: setIsOpenedLaboratory,
                getState: isOpenedLaboratory
              },
              isConnectedUserAdmin
            )}
          {!isLoading &&
            user?.designCenter &&
            Object.keys(user?.designCenter).length > 0 &&
            displayEstablishment(user.designCenter, EstablishmentPayload.DESIGN_CENTER, {
              setState: setIsOpenedDesignCenter,
              getState: isOpenedDesignCenter
            })}
          <section className={styles['user-detail__content__section']}>
            <header className={styles['user-detail__content__section__header']}>
              <Text
                label={t('userForm.customizationGroup')}
                size="s"
                className={styles['user-detail__content__section__header__title']}
                type="title"
              />
              {isReadOnly && (
                <IconButton
                  color={ColorPropsEnum.PRIMARY}
                  iconSize="sm"
                  faIconClass="fa-pen-to-square"
                  onClick={() => setIsOpenedCustomization(true)}
                  radius="full"
                />
              )}
            </header>
            <div className={styles['user-detail__content__section__main__item']}>
              <Text color={ColorPropsEnum.GREY} label={t('userForm.language')}></Text>
              {isLoading ? (
                <Skeleton type="text" />
              ) : (
                <Text
                  label={
                    user?.language && Object.values(Language).includes(user.language)
                      ? t(`language.${user.language}`, { ns: 'common' })
                      : t('userForm.unknown')
                  }></Text>
              )}
            </div>
            <div>
              <Text color={ColorPropsEnum.GREY} label={t('userForm.dentalNotation')}></Text>
              {isLoading ? <Skeleton type="text" /> : <Text label={dentalNotation}></Text>}
            </div>
            <SideBarModal
              title={t('userForm.customizationGroup')}
              isOpened={isOpenedCustomization}
              onClose={() => setIsOpenedCustomization(false)}>
              <CustomizationForm
                saveCallback={handleSubmit}
                closeSideNavCallback={setIsOpenedCustomization}
              />
            </SideBarModal>
          </section>
        </div>
      </>
    )
  );
};

export default UserDetails;
