import {
  FilterProps,
  MasterDetailsProps,
  MenuEntry,
  TypeColWithNamePropertyPlatform
} from '../../../../models/datagrid';
import i18next from 'i18next';
import {
  Chips,
  DropdownMenu,
  IconButton,
  Link,
  Text,
  Tooltip,
  TooltipContent
} from '@anatoscope/circlestorybook';
import moment from 'moment/moment';
import SelectFilter from '@inovua/reactdatagrid-enterprise/SelectFilter';
import styles from './components-page.module.scss';
import stylesDataGrid from '../../../../features/datagrid/datagrid-feature.module.scss';
import { Component, ComponentRowDetails } from '../../../../models/component';
import { Brand, CommonTypes } from '../../../../models/common-types';
import { getLocalizedProperty } from '../catalog';
import { TypeRowDetailsInfo } from '@inovua/reactdatagrid-community/types/TypeDataGridProps';
import { ColorPropsEnum } from '../../../../enum/color.enum';
import {
  computeMasterDetailsHeight,
  getColumnOptions
} from '../../../../features/datagrid/datagrid.utils.ts';

export const getValue = (data: object | undefined, valueName: string): string => {
  return data
    ? Object.entries(data)
        .filter((v) => v.length > 0 && v[0] === valueName)
        .map((v) => v[1].toString())[0]
    : '';
};

export const getColumnSettings = (
  commonTypes: CommonTypes,
  deactivateCallback: (component: Component) => void
): TypeColWithNamePropertyPlatform[] => [
  {
    ...getColumnOptions(
      'deletedAt',
      i18next.t('components.datagrid.columns.status', { ns: 'catalog' }),
      1,
      false
    ),
    filterEditor: SelectFilter,
    filterEditorProps: {
      dataSource: [
        { id: 'active', label: i18next.t('active', { ns: 'common' }) },
        { id: 'inactive', label: i18next.t('inactive', { ns: 'common' }) }
      ]
    },
    render: ({ value }) => {
      let chip = (
        <Chips
          firstLabel={i18next.t(value ? 'inactive' : 'active', { ns: 'common' })}
          color={value ? ColorPropsEnum.WARNING : ColorPropsEnum.SUCCESS}
        />
      );
      if (value) {
        chip = (
          <Tooltip>
            <TooltipContent>
              {moment(value).format(i18next.t('date.full', { ns: 'common' }))}
            </TooltipContent>
            {chip}
          </Tooltip>
        );
      }
      return chip;
    }
  },
  {
    ...getColumnOptions(
      'name',
      i18next.t('components.datagrid.columns.name', { ns: 'catalog' }),
      2
    ),
    render: ({ data }) => {
      const labelLocal = getValue(data, getLocalizedProperty('label'));
      return <Link href={`/catalog/components/${data.id}/detail`} label={`${labelLocal}`}></Link>;
    }
  },
  {
    ...getColumnOptions(
      'componentType',
      i18next.t('components.datagrid.columns.component_type', { ns: 'catalog' }),
      1,
      false
    ),
    filterEditor: SelectFilter,
    filterEditorProps: {
      multiple: true,
      wrapMultiple: false,
      dataSource:
        commonTypes.componentTypes &&
        Object.keys(commonTypes.componentTypes).map((type) => ({
          id: type,
          label: i18next.t(`componentTypes.${type}`, { ns: 'catalog' })
        }))
    },
    render: ({ value }) => {
      return <span>{value ? i18next.t(`componentTypes.${value}`, { ns: 'catalog' }) : '-'}</span>;
    }
  },
  {
    ...getColumnOptions(
      'materials',
      i18next.t('components.datagrid.columns.materials', { ns: 'catalog' }),
      1,
      false
    ),
    filterEditor: SelectFilter,
    filterEditorProps: {
      multiple: true,
      wrapMultiple: false,
      // go through Set to get a unique labels list
      dataSource: [...new Set(commonTypes?.materials.map((material) => material.code))].map(
        (material) => ({
          id: material,
          label: i18next.t(`materials.${material}`, { ns: 'catalog' })
        })
      )
    },
    render: ({ value }) => {
      return (
        <p>
          {value
            ? i18next.t(`materials.counter`, {
                count: value.length,
                ns: 'catalog'
              })
            : '-'}
        </p>
      );
    }
  },
  {
    ...getColumnOptions(
      'shapes',
      i18next.t('components.datagrid.columns.shapes', { ns: 'catalog' }),
      1,
      false
    ),
    filterEditor: SelectFilter,
    filterEditorProps: {
      multiple: true,
      wrapMultiple: false,
      dataSource: commonTypes?.shapes?.map((shape) => ({
        id: shape.code,
        label: i18next.t(`shapes.${shape.code}`, { ns: 'catalog' })
      }))
    },
    textEllipsis: false,
    render: ({ value }) => {
      return (
        <p>
          {value
            ? i18next.t(`shapes.counter`, {
                count: value.length,
                ns: 'catalog'
              })
            : '-'}
        </p>
      );
    }
  },
  {
    ...getColumnOptions(
      'structures',
      i18next.t('components.datagrid.columns.structures', { ns: 'catalog' }),
      1,
      false
    ),
    filterEditor: SelectFilter,
    filterEditorProps: {
      multiple: true,
      wrapMultiple: false,
      dataSource: commonTypes?.structures?.map((structure) => ({
        id: structure.code,
        label: i18next.t(`structures.${structure.code}`, { ns: 'catalog' })
      }))
    },
    render: ({ value }) => {
      return (
        <p>
          {value
            ? i18next.t(`structures.counter`, {
                count: value.length,
                ns: 'catalog'
              })
            : '-'}
        </p>
      );
    }
  },
  {
    ...getColumnOptions(
      'shades',
      i18next.t('components.datagrid.columns.shades', { ns: 'catalog' }),
      1,
      false
    ),
    filterEditor: SelectFilter,
    filterEditorProps: {
      multiple: true,
      wrapMultiple: false,
      dataSource: commonTypes?.shades?.map((shade) => ({
        id: shade.code,
        label: shade.code
      }))
    },
    render: ({ value }) => {
      return (
        <p>
          {value
            ? i18next.t(`shades.counter`, {
                count: value.length,
                ns: 'catalog'
              })
            : '-'}
        </p>
      );
    }
  },
  {
    ...getColumnOptions(
      'brands',
      i18next.t('components.datagrid.columns.brands', { ns: 'catalog' }),
      1,
      false
    ),
    filterEditor: SelectFilter,
    filterEditorProps: {
      multiple: true,
      wrapMultiple: false,
      dataSource: commonTypes?.brands?.map((brand) => ({
        id: brand.id,
        label: brand.brandName
      }))
    },
    render: ({ value }) => {
      return (
        <p>
          {value
            ? i18next.t(`brands.counter`, {
                count: value.length,
                ns: 'catalog'
              })
            : '-'}
        </p>
      );
    }
  },
  {
    ...getColumnOptions(
      'angulations',
      i18next.t('components.datagrid.columns.angulations', { ns: 'catalog' }),
      1,
      false
    ),
    filterEditor: SelectFilter,
    filterEditorProps: {
      multiple: true,
      wrapMultiple: false,
      dataSource: commonTypes?.angulations?.map((angulation) => ({
        id: angulation.code,
        label: i18next.t(`angulations.${angulation.code}`, { ns: 'catalog' })
      }))
    },
    render: ({ value }) => {
      return (
        <p>
          {value
            ? i18next.t(`angulations.counter`, {
                count: value.length,
                ns: 'catalog'
              })
            : '-'}
        </p>
      );
    }
  },
  {
    ...getColumnOptions(
      'aspects',
      i18next.t('components.datagrid.columns.aspects', { ns: 'catalog' }),
      1,
      false
    ),
    filterEditor: SelectFilter,
    filterEditorProps: {
      multiple: true,
      wrapMultiple: false,
      dataSource: commonTypes?.aspects?.map((aspect) => ({
        id: aspect.code,
        label: i18next.t(`aspects.${aspect.code}`, { ns: 'catalog' })
      }))
    },
    render: ({ value }) => {
      return (
        <p>
          {value
            ? i18next.t(`aspects.counter`, {
                count: value.length,
                ns: 'catalog'
              })
            : '-'}
        </p>
      );
    }
  },
  {
    ...getColumnOptions(
      'implantAttachments',
      i18next.t('components.datagrid.columns.implantAttachments', { ns: 'catalog' }),
      1,
      false
    ),
    filterEditor: SelectFilter,
    filterEditorProps: {
      multiple: true,
      wrapMultiple: false,
      dataSource: Object.values(commonTypes?.implantAttachments)?.map((attachment) => ({
        id: attachment,
        label: i18next.t(`implantAttachments.${attachment}`, { ns: 'catalog' })
      }))
    },
    render: ({ value }) => {
      return (
        <p>
          {value
            ? i18next.t(`implantAttachments.counter`, {
                count: value.length,
                ns: 'catalog'
              })
            : '-'}
        </p>
      );
    }
  },
  {
    ...getColumnOptions(
      'toothStratificationTechniques',
      i18next.t('components.datagrid.columns.toothStratificationTechniques', { ns: 'catalog' }),
      1,
      false
    ),
    filterEditor: SelectFilter,
    filterEditorProps: {
      multiple: true,
      wrapMultiple: false,
      dataSource: Object.values(commonTypes?.toothStratificationTechniques)?.map(
        (toothStratification) => ({
          id: toothStratification,
          label: i18next.t(`toothStratificationTechniques.${toothStratification}`, {
            ns: 'catalog'
          })
        })
      )
    },
    render: ({ value }) => {
      return (
        <p>
          {value
            ? i18next.t(`toothStratificationTechniques.counter`, {
                count: value.length,
                ns: 'catalog'
              })
            : '-'}
        </p>
      );
    }
  },
  {
    ...getColumnOptions('menu', '', 1, false),
    maxWidth: 50,
    showColumnMenuTool: false,
    render: ({ data }) => {
      return (
        <DropdownMenu
          renderTargetButton={({ active }: { active: boolean }) => (
            <IconButton faIconClass="ellipsis-vertical" isActive={active} radius="full" />
          )}
          data={buildMenu(data, deactivateCallback)}
        />
      );
    }
  }
];

export const renderMasterDetails = ({ data }: TypeRowDetailsInfo) => {
  const component = data as ComponentRowDetails;

  return component ? (
    <div className={styles['components-page__datagrid__details']}>
      {displayDetailsElement(component.materials, 'materials')}
      {displayDetailsElement(component.shapes, 'shapes')}
      {displayDetailsElement(component.structures, 'structures')}
      {displayDetailsElement(component.shades, 'shades')}
      {displayBrandDetailsElement(component.brandTypes, component.brands)}
      {displayDetailsElement(component.angulations, 'angulations')}
      {displayDetailsElement(component.aspects, 'aspects')}
      {displayDetailsElement(component.implantAttachments, 'implantAttachments')}
      {displayDetailsElement(
        component.toothStratificationTechniques,
        'toothStratificationTechniques'
      )}
      {component.manufacturingProcess && (
        <div className={styles['components-page__datagrid__details__row']}>
          <Text
            className={styles['components-page__datagrid__details__row__title']}
            size="s"
            label={i18next.t('components.componentForm.manufacturingProcess.detailLabel', {
              ns: 'catalog'
            })}
          />
          <div>
            <Chips
              key={component.manufacturingProcess}
              className={stylesDataGrid['datagrid-feature__chips']}
              color={ColorPropsEnum.FAMILY_GUARDS}
              firstLabel={i18next.t(`manufacturingProcesses.${component.manufacturingProcess}`, {
                ns: 'catalog'
              })}
            />
          </div>
        </div>
      )}
    </div>
  ) : (
    ''
  );
};

export const masterDetailsHeight = ({ data }: MasterDetailsProps) => {
  const component = data as ComponentRowDetails;

  return computeMasterDetailsHeight(34, 0, 25, [
    component.materials,
    component.shades,
    component.structures,
    component.shapes,
    component.brands,
    component.angulations,
    component.aspects,
    component.implantAttachments,
    component.toothStratificationTechniques,
    component.manufacturingProcess
  ]);
};

const displayDetailsElement = (array: Array<string> | undefined, name?: string) => {
  return (
    array && (
      <div className={styles['components-page__datagrid__details__row']}>
        <Text
          className={styles['components-page__datagrid__details__row__title']}
          size="s"
          label={i18next.t(`components.datagrid.columns.${name}`, { ns: 'catalog' })}
        />
        <div>
          {array.map((element: string) => (
            <Chips
              key={element}
              className={stylesDataGrid['datagrid-feature__chips']}
              color={
                name === 'shades'
                  ? ColorPropsEnum[element.toLocaleUpperCase() as keyof typeof ColorPropsEnum]
                  : ColorPropsEnum.FAMILY_GUARDS
              }
              firstLabel={i18next.t(name !== 'shades' ? name + '.' + element : element, {
                ns: 'catalog'
              })}
            />
          ))}
        </div>
      </div>
    )
  );
};

const displayBrandDetailsElement = (
  brands: Brand[] | undefined,
  brandIds: Array<string> | undefined
) => {
  return (
    brandIds &&
    brands && (
      <div className={styles['components-page__datagrid__details__row']}>
        <Text
          className={styles['components-page__datagrid__details__row__title']}
          size="s"
          label={i18next.t(`components.datagrid.columns.brands`, { ns: 'catalog' })}
        />
        <div>
          {brandIds.map((element: string) => (
            <Chips
              key={element}
              color={ColorPropsEnum.FAMILY_GUARDS}
              className={stylesDataGrid['datagrid-feature__chips']}
              firstLabel={brands.find((brand) => brand.id === +element)?.brandName}
            />
          ))}
        </div>
      </div>
    )
  );
};

const buildMenu = (
  component: Component,
  deactivateCallback: (component: Component) => void
): MenuEntry[][] => {
  return [
    [
      {
        label: i18next.t('action.detail', { ns: 'common' }),
        type: 'nav',
        link: `/catalog/components/${component.id}/detail`
      }
    ],
    [
      {
        disabled: component.deletedAt !== undefined,
        label: i18next.t('action.deactivate', { ns: 'common' }),
        onClick: () => {
          deactivateCallback(component);
        },
        type: 'button'
      }
    ]
  ];
};

export const getFilters = (filterValue: Array<FilterProps> | undefined) => {
  let filters = '';
  filterValue
    ?.filter((filter) => filter.value)
    ?.forEach((filter) => {
      switch (filter.name) {
        case 'deletedAt':
          filters = `${filters}&filter.deletedAt=${
            filter.value === 'active' ? '$null' : '$not:$null'
          }`;
          break;
        case 'structure':
        case 'angulation':
        case 'brand':
        case 'aspect':
        case 'shade':
        case 'shape':
        case 'material':
        case 'componentType': {
          const multipleSelectValues = filter.value as Array<string>;
          filters = `${filters}&filter.${filter.name}=$in:${multipleSelectValues.join(',')}`;
          break;
        }
        case 'name':
          filters = `${filters}&filter.${getLocalizedProperty(filter.name)}=$ilike:${filter.value}`;
          break;
        default:
          filters = `${filters}&filter.${filter.name}=$ilike:${filter.value}`;
          break;
      }
    });
  return filters;
};

export const rowClassName = ({ data }: { data: Component }): string | undefined => {
  if (data.deletedAt) {
    return stylesDataGrid['datagrid-feature__row--disabled'];
  }
  return undefined;
};
