import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import {
  circleOneCompatibilitySelector,
  productSelector
} from '../../../../../../store/products/products.selectors';
import useForm from '../../../../../../utils/useForm';
import { productsActions } from '../../../../../../store/products/products.reducers';
import styles from '../create-product-page.module.scss';
import {
  Button,
  Checkbox,
  Dropdown,
  Fieldset,
  TextField,
  RadioList,
  Tooltip,
  TooltipContent
} from '@anatoscope/circlestorybook';
import { CreateProductNavProps } from '../CreateProductPage';
import {
  stumpModesDropdownSelector,
  teethModesDropdownSelector
} from '../../../../../../store/common-types/common-types.selectors';
import { StringObject } from '../../../../../../models/common';
import { CheckboxStringItem } from '../../../../../../models/form';
import {
  CircleCadProductTypeEnum,
  CircleOneCompatibilityEnum,
  InjectionModeEnum
} from '../../../../../../enum/product.enum';
import { ColorPropsEnum } from '../../../../../../enum/color.enum';
import { ProductCreationForm } from '../../../../../../models/product.tsx';
import { circleOneCompatibilitiesRadioItems } from '../../../../../../utils/utils.tsx';
import { useGetCommonTypesQuery } from '../../../../../../services/common-types-rtkq.services.ts';
import { useGetCircleCadVersionsQuery } from '../../../../../../services/circle-cad-version.services.ts';
import { activeCircleCadVersionsDroprownSelector } from '../../../../../../store/circle-cad-versions/circle-cad-versions.selectors.ts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';

const ConfigurationForm = ({ nextStepCallback, previousStepCallback }: CreateProductNavProps) => {
  const { t } = useTranslation(['catalog']);
  const dispatch = useAppDispatch();
  const product = useAppSelector(productSelector);
  const { isLoading: areCommonTypesLoading } = useGetCommonTypesQuery();
  const stumpModesDropdownData = useAppSelector(stumpModesDropdownSelector);
  const teethModesDropdownData = useAppSelector(teethModesDropdownSelector);
  const { isLoading: areCircleCadVersionsLoading } = useGetCircleCadVersionsQuery();
  const circleCadVersions = useAppSelector(activeCircleCadVersionsDroprownSelector);
  const circleOneCompatibility = useAppSelector(circleOneCompatibilitySelector);
  const productProportionalBilling = product?.proportionalBilling;

  // Form configuration
  const submitCallback = () => {
    const { circleOneCompatibility, ...productPropertiesValues } = values;
    dispatch(
      productsActions.setProduct({
        ...product,
        ...productPropertiesValues,
        proportionalBilling: proportionalBilling?.[0].isChecked ?? false,
        injectionMode: injectionMode[0].isChecked
          ? InjectionModeEnum.SELECTABLE
          : InjectionModeEnum.NONE,
        isChairSide: circleOneCompatibility === CircleOneCompatibilityEnum.CHAIRSIDE,
        isOneDesign: circleOneCompatibility === CircleOneCompatibilityEnum.ONEDESIGN
      } as ProductCreationForm)
    );
    if (nextStepCallback) nextStepCallback();
  };

  const { values, handleChange, handleBlur, handleSubmit, handleSelect, handleCheck, errors } =
    useForm(
      {
        proportionalBilling: [
          {
            value: 'proportionalBilling',
            isChecked: productProportionalBilling || false
          }
        ],
        circleCadVersion: product?.circleCadVersion || '',
        circleCadProductType: product?.circleCadProductType || '',
        stumpMode: product?.stumpMode,
        teethMode: product?.teethMode,
        injectionMode: [
          {
            value: 'injectionMode',
            isChecked: product?.injectionMode === InjectionModeEnum.SELECTABLE
          }
        ],
        circleOneCompatibility: circleOneCompatibility
      },
      submitCallback,
      () => {
        const newErrors: StringObject = {};
        if (values['circleCadVersion'] && !values['circleCadProductType']) {
          newErrors['circleCadProductType'] = 'empty';
        }
        ['stumpMode', 'teethMode'].forEach((key) => {
          if (!values[key]) {
            newErrors[key] = 'empty';
          }
        });
        return newErrors;
      }
    );

  const proportionalBilling = values.proportionalBilling as CheckboxStringItem[];
  const injectionMode = values.injectionMode as CheckboxStringItem[];
  return (
    <form onSubmit={handleSubmit} className={styles['create-product-page__form']}>
      <Fieldset size="m" className={styles['create-product-page__form__fieldset']}>
        <Dropdown
          id="circleCadVersion"
          name="circleCadVersion"
          data={areCircleCadVersionsLoading ? [] : [circleCadVersions]}
          value={values.circleCadVersion}
          placeholder={t('form.select', { ns: 'common' })}
          className={styles['create-product-page__form__fieldset__sub-fieldset']}
          onChange={(newValue: string) => {
            handleSelect(newValue, 'circleCadVersion');
            if (!newValue) {
              handleSelect('', 'circleCadProductType');
            }
          }}
          label={t('products.productForm.circleCadVersion.label')}
          helperText={
            errors.circleCadVersion
              ? t(`products.productForm.circleCadVersion.${errors.circleCadVersion}`)
              : t('products.productForm.circleCadVersion.helper')
          }
          variant={
            errors.circleCadVersion ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT
          }></Dropdown>

        {values.circleCadVersion && (
          <div className={styles['create-product-page__form__fieldset__container']}>
            <TextField
              id="circleCadProductType"
              name="circleCadProductType"
              value={values.circleCadProductType}
              label={t('products.productForm.circleCadProductType.label')}
              placeholder={t('products.productForm.circleCadProductType.placeholder')}
              onBlur={handleBlur}
              onChange={handleChange}
              helperText={
                errors.imageUrl
                  ? t('products.productForm.circleCadProductType.error')
                  : t('products.productForm.circleCadProductType.helper')
              }
              className={styles['create-product-page__form__fieldset__sub-fieldset']}
              variant={
                errors.circleCadProductType ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT
              }></TextField>
            <Tooltip isClickable={true}>
              <FontAwesomeIcon
                className={styles['create-product-page__form__fieldset__container__information']}
                icon={faCircleInfo}
                color={ColorPropsEnum.GREY}
              />
              <TooltipContent>
                <ul>
                  {Object.keys(CircleCadProductTypeEnum).map((productType, index) => (
                    <li key={index}>{productType}</li>
                  ))}
                </ul>
              </TooltipContent>
            </Tooltip>
          </div>
        )}

        <div className={styles['create-product-page__form__fieldset__checkbox']}>
          <RadioList
            data-cy="radiolist-circleOneCompatibility"
            title={t('products.productForm.circleOneCompatibility.label')}
            options={circleOneCompatibilitiesRadioItems}
            name="circleOneCompatibility"
            selectedValue={values.circleOneCompatibility}
            onClick={(newValue: CircleOneCompatibilityEnum) =>
              handleSelect(newValue, 'circleOneCompatibility')
            }
            displayedInRow={true}
            className={styles['']}
          />
        </div>

        <div>
          <Dropdown
            data={areCommonTypesLoading ? [] : [teethModesDropdownData]}
            value={values.teethMode}
            isLoading={areCommonTypesLoading}
            label={t('products.productForm.teethMode.label')}
            placeholder={t('form.select', { ns: 'common' })}
            className={styles['create-product-page__form__fieldset__sub-fieldset']}
            onChange={(newValue: string) => handleSelect(newValue, 'teethMode')}
            variant={errors.teethMode ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
            helperText={
              errors.teethMode
                ? t(`products.productForm.teethMode.${errors.teethMode}`)
                : t('products.productForm.teethMode.helper')
            }></Dropdown>
          <Dropdown
            data={areCommonTypesLoading ? [] : [stumpModesDropdownData]}
            value={values.stumpMode}
            isLoading={areCommonTypesLoading}
            label={t('products.productForm.stumpMode.label')}
            placeholder={t('form.select', { ns: 'common' })}
            onChange={(newValue: string) => handleSelect(newValue, 'stumpMode')}
            variant={errors.stumpMode ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
            className={styles['create-product-page__form__fieldset__sub-fieldset']}
            helperText={
              errors.stumpMode
                ? t(`products.productForm.stumpMode.${errors.stumpMode}`)
                : t('products.productForm.stumpMode.helper')
            }></Dropdown>
          <div className={styles['create-product-page__form__fieldset__checkbox']}>
            <Checkbox
              data-cy="checkbox-injection-mode"
              label={t('products.productForm.injectionMode.label')}
              isChecked={injectionMode[0].isChecked}
              onClick={() => handleCheck('injectionMode', 'injectionMode')}
            />
          </div>
          <div className={styles['create-product-page__form__fieldset__checkbox__container']}>
            <div className={styles['create-product-page__form__fieldset__checkbox']}>
              <Checkbox
                data-cy="checkbox-proportional-billing"
                label={t('products.productForm.proportionalBilling.label')}
                helperText={t('products.productForm.proportionalBilling.helper')}
                isChecked={proportionalBilling[0].isChecked}
                onClick={() => handleCheck('proportionalBilling', 'proportionalBilling')}
              />
            </div>
          </div>
        </div>
      </Fieldset>
      <div className="form__buttons">
        <Button
          label={t('action.previous', { ns: 'common' })}
          category="secondary"
          onClick={previousStepCallback}
          iconLeft="fa-chevron-left"
        />
        <Button
          label={t('action.next', { ns: 'common' })}
          type="submit"
          iconRight="fa-chevron-right"
        />
      </div>
    </form>
  );
};

export default ConfigurationForm;
