import { useTranslation } from 'react-i18next';
import useForm from '../../../../../../utils/useForm';
import {
  Box,
  Button,
  TextField,
  Dropdown,
  Checkbox,
  RadioList,
  Tooltip,
  TooltipContent
} from '@anatoscope/circlestorybook';
import { Product, ProductUpdateForm } from '../../../../../../models/product';
import { ColorPropsEnum } from '../../../../../../enum/color.enum';
import styles from './edit-product-forms.module.scss';
import { useGetCommonTypesQuery } from '../../../../../../services/common-types-rtkq.services';
import { StringObject } from '../../../../../../models/common';
import { useGetCircleCadVersionsQuery } from '../../../../../../services/circle-cad-version.services';
import {
  CircleCadProductTypeEnum,
  CircleOneCompatibilityEnum,
  InjectionModeEnum
} from '../../../../../../enum/product.enum';
import { CheckboxStringItem } from '../../../../../../models/form';
import {
  circleOneCompatibilitiesRadioItems,
  getCircleOneCompatibility
} from '../../../../../../utils/utils';
import { usePatchProductMutation } from '../../../../../../services/products-rtkq.services';
import { useAppSelector } from '../../../../../../store/hooks';
import {
  stumpModesDropdownSelector,
  teethModesDropdownSelector
} from '../../../../../../store/common-types/common-types.selectors';
import { activeCircleCadVersionsDroprownSelector } from '../../../../../../store/circle-cad-versions/circle-cad-versions.selectors';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons/faCircleInfo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
  productToEdit: Product;
  onSubmitCallback?: () => void;
};

const ConfigurationForm = ({ productToEdit, onSubmitCallback }: Props) => {
  const { t } = useTranslation(['catalog']);
  const { isLoading: areCommonTypesLoading } = useGetCommonTypesQuery();
  const { isLoading: isLoadingCircleCadVersions } = useGetCircleCadVersionsQuery();

  const stumpModesDropdownData = useAppSelector(stumpModesDropdownSelector);
  const teethModesDropdownData = useAppSelector(teethModesDropdownSelector);

  const circleCadVersionsDropdown = useAppSelector(activeCircleCadVersionsDroprownSelector);

  const productProportionalBilling = productToEdit?.proportionalBilling;
  const circleOneCompatibility = getCircleOneCompatibility(productToEdit);
  const [updateProduct, { isLoading: isPatchLoading }] = usePatchProductMutation();

  const onSubmit = async () => {
    await updateProduct({
      id: productToEdit.id?.toString(),
      circleCadVersion: values.circleCadVersion,
      circleCadProductType: values.circleCadProductType,
      teethMode: values.teethMode,
      stumpMode: values.stumpMode,
      injectionMode: injectionMode[0].isChecked
        ? InjectionModeEnum.SELECTABLE
        : InjectionModeEnum.NONE,
      proportionalBilling: proportionalBilling?.[0].isChecked ?? false,
      isChairSide: values.circleOneCompatibility === CircleOneCompatibilityEnum.CHAIRSIDE,
      isOneDesign: values.circleOneCompatibility === CircleOneCompatibilityEnum.ONEDESIGN
    } as ProductUpdateForm);

    if (onSubmitCallback) onSubmitCallback();
  };

  const { values, handleChange, handleBlur, handleSubmit, handleSelect, handleCheck, errors } =
    useForm(
      {
        proportionalBilling: [
          {
            value: 'proportionalBilling',
            isChecked: productProportionalBilling || false
          }
        ],
        circleCadVersion: productToEdit?.circleCadVersion || '',
        circleCadProductType: productToEdit?.circleCadProductType || '',
        stumpMode: productToEdit?.stumpMode,
        teethMode: productToEdit?.teethMode,
        injectionMode: [
          {
            value: 'injectionMode',
            isChecked: productToEdit?.injectionMode === InjectionModeEnum.SELECTABLE
          }
        ],
        circleOneCompatibility: circleOneCompatibility
      },
      onSubmit,
      () => {
        const newErrors: StringObject = {};
        if (values['circleCadVersion'] && !values['circleCadProductType']) {
          newErrors['circleCadProductType'] = 'empty';
        }
        ['stumpMode', 'teethMode'].forEach((key) => {
          if (!values[key]) {
            newErrors[key] = 'empty';
          }
        });
        return newErrors;
      }
    );

  const proportionalBilling = values.proportionalBilling as CheckboxStringItem[];
  const injectionMode = values.injectionMode as CheckboxStringItem[];

  return (
    <Box color={ColorPropsEnum.WHITE}>
      <form onSubmit={handleSubmit}>
        <Dropdown
          id="circleCadVersion"
          name="circleCadVersion"
          data={isLoadingCircleCadVersions ? [] : [circleCadVersionsDropdown]}
          value={values.circleCadVersion}
          placeholder={t('form.select', { ns: 'common' })}
          className={styles['edit-product-forms__dropdown']}
          onChange={(newValue: string) => {
            handleSelect(newValue, 'circleCadVersion');
            if (!newValue) {
              handleSelect('', 'circleCadProductType');
            }
          }}
          label={t('products.productForm.circleCadVersion.label')}
          helperText={
            errors.circleCadVersion
              ? t(`products.productForm.circleCadVersion.${errors.circleCadVersion}`)
              : t('products.productForm.circleCadVersion.helper')
          }
          variant={errors.circleCadVersion ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
          data-cy="configuration__circle-cad-version"></Dropdown>

        {values.circleCadVersion && (
          <div className={styles['edit-product-forms__container']}>
            <TextField
              id="circleCadProductType"
              name="circleCadProductType"
              value={values.circleCadProductType}
              label={t('products.productForm.circleCadProductType.label')}
              placeholder={t('products.productForm.circleCadProductType.placeholder')}
              onBlur={handleBlur}
              onChange={handleChange}
              helperText={
                errors.imageUrl
                  ? t('products.productForm.circleCadProductType.error')
                  : t('products.productForm.circleCadProductType.helper')
              }
              className={[
                styles['edit-product-forms__input'],
                styles['edit-product-forms__container__input']
              ].join(' ')}
              variant={errors.circleCadProductType ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
              data-cy="configuration__circle-cad-product-type"></TextField>
            <Tooltip isClickable={true}>
              <FontAwesomeIcon
                className={styles['edit-product-forms__container__information']}
                icon={faCircleInfo}
                color={ColorPropsEnum.GREY}
              />
              <TooltipContent>
                <ul>
                  {Object.keys(CircleCadProductTypeEnum).map((productType, index) => (
                    <li key={index}>{productType}</li>
                  ))}
                </ul>
              </TooltipContent>
            </Tooltip>
          </div>
        )}

        <div className={styles['edit-product-forms__radiolist']}>
          <RadioList
            title={t('products.productForm.circleOneCompatibility.label')}
            options={circleOneCompatibilitiesRadioItems}
            name="circleOneCompatibility"
            selectedValue={values.circleOneCompatibility}
            onClick={(newValue: CircleOneCompatibilityEnum) =>
              handleSelect(newValue, 'circleOneCompatibility')
            }
            className={styles['']}
            data-cy="configuration__circle-one-compatibility"
            displayedInRow={true}
          />
        </div>

        <div>
          <Dropdown
            data={areCommonTypesLoading ? [] : [teethModesDropdownData]}
            value={values.teethMode}
            isLoading={areCommonTypesLoading}
            label={t('products.productForm.teethMode.label')}
            placeholder={t('form.select', { ns: 'common' })}
            className={styles['edit-product-forms__dropdown']}
            onChange={(newValue: string) => handleSelect(newValue, 'teethMode')}
            variant={errors.teethMode ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
            helperText={
              errors.teethMode
                ? t(`products.productForm.teethMode.${errors.teethMode}`)
                : t('products.productForm.teethMode.helper')
            }
            data-cy="configuration__teeth-mode"></Dropdown>
          <Dropdown
            data={areCommonTypesLoading ? [] : [stumpModesDropdownData]}
            value={values.stumpMode}
            isLoading={areCommonTypesLoading}
            label={t('products.productForm.stumpMode.label')}
            placeholder={t('form.select', { ns: 'common' })}
            onChange={(newValue: string) => handleSelect(newValue, 'stumpMode')}
            variant={errors.stumpMode ? ColorPropsEnum.DANGER : ColorPropsEnum.DEFAULT}
            className={styles['edit-product-forms__dropdown']}
            helperText={
              errors.stumpMode
                ? t(`products.productForm.stumpMode.${errors.stumpMode}`)
                : t('products.productForm.stumpMode.helper')
            }
            data-cy="configuration__stump-mode"></Dropdown>
          <div className={styles['create-product-page__form__fieldset__checkbox']}>
            <Checkbox
              data-cy="checkbox-injection-mode"
              label={t('products.productForm.injectionMode.label')}
              isChecked={injectionMode[0].isChecked}
              onClick={() => handleCheck('injectionMode', 'injectionMode')}
              className={styles['edit-product-forms__checkbox']}
            />
          </div>
          <div className={styles['create-product-page__form__fieldset__checkbox__container']}>
            <div className={styles['create-product-page__form__fieldset__checkbox']}>
              <Checkbox
                data-cy="checkbox-proportional-billing"
                label={t('products.productForm.proportionalBilling.label')}
                helperText={t('products.productForm.proportionalBilling.helper')}
                isChecked={proportionalBilling[0].isChecked}
                onClick={() => handleCheck('proportionalBilling', 'proportionalBilling')}
                className={styles['edit-product-forms__checkbox']}
              />
            </div>
          </div>
        </div>
        <div className={styles['edit-product-forms__form-button']}>
          <Button
            label={t('action.update', { ns: 'common' })}
            type="submit"
            isLoading={isPatchLoading}
            data-cy="configuration__submit"
          />
        </div>
      </form>
    </Box>
  );
};

export default ConfigurationForm;
