// This is temporary
// To Do: add a date format parameter (short / medium / long )
// handle locale
// separator should not be in parameters
// Use moment library

import i18next from '../i18n.tsx';
import { ErrorCode, ToastType } from '../enum/feedback';
import {
  AdminRole,
  DentalNotation,
  DentistRole,
  DesignerRole,
  LabUserRole,
  Language
} from '../enum/user';
import { EstablishmentType } from '../enum/establishment';
import { HttpStatusCode } from 'axios';
import { CircleOneCompatibilityEnum } from '../enum/product.enum';
import { Product, ProductCreationForm } from '../models/product';
import { RadioItem } from '../models/form';
import { forceRefreshToken } from '../services/firebase.services.tsx';
import { Dispatch } from 'react';
import { feedbackActions } from '../store/feedback/feedback.reducer.tsx';

export const formatDate = (date: Date, options?: Intl.DateTimeFormatOptions) => {
  options = options
    ? { ...options, hour12: false }
    : {
        hour12: false,
        hour: '2-digit',
        minute: '2-digit',
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      };
  return date.toLocaleString(i18next.language, options);
};

/* We specify this rule because we don't know the payload type, and we use explicitly error as object after.*/
/* So this is an emergency case, don't reproduce */
/* eslint-disable  @typescript-eslint/no-explicit-any */
export const getMessageError = (error: any): string => {
  let message = i18next.t('generic', { ns: 'error' });

  // switch between axios & rtk.
  const errorDetails = 'response' in error ? error.response : error;

  if ('data' in errorDetails) {
    if (typeof errorDetails.data === 'object') {
      // Handle error from our backend
      if (Object.values(ErrorCode).includes(errorDetails.data.error)) {
        message = i18next.t(errorDetails.data.error, { ns: 'error' });
      } else if (errorDetails.status !== HttpStatusCode.InternalServerError) {
        message = errorDetails.data.message;
      }
    }
  }

  return message;
};

export const capitalize = (string: string) => {
  return string?.toLowerCase().charAt(0).toUpperCase() + string?.toLowerCase().slice(1);
};

export const isDentistRole = (
  userRole: LabUserRole | AdminRole | DentistRole | DesignerRole
): boolean => {
  return Object.keys(DentistRole).includes(userRole);
};

export const isDesignerRole = (
  userRole: LabUserRole | AdminRole | DentistRole | DesignerRole
): boolean => {
  return Object.keys(DesignerRole).includes(userRole);
};

export const isAdminRole = (
  userRole: LabUserRole | AdminRole | DentistRole | DesignerRole
): boolean => {
  return Object.keys(AdminRole).includes(userRole);
};

export const isLaboratory = (establishmentType: EstablishmentType): boolean => {
  return establishmentType === EstablishmentType.LABORATORY;
};

export const isClinic = (establishmentType: string): boolean => {
  return establishmentType === EstablishmentType.CLINIC;
};

export const isDesignCenter = (establishmentType: string): boolean => {
  return establishmentType === EstablishmentType.DESIGN_CENTER;
};

export const dentalNotations = Object.keys(DentalNotation).map((value) => {
  return {
    label: i18next.t(`userForm.${value.toLowerCase()}.label`, { ns: 'user' }),
    value: value,
    helperText: i18next.t(`userForm.${value.toLowerCase()}.helperText`, {
      ns: 'user'
    })
  };
});

export const getCircleOneCompatibility = (product: Product | ProductCreationForm | undefined) => {
  if (product?.isChairSide) {
    return CircleOneCompatibilityEnum.CHAIRSIDE;
  }
  if (product?.isOneDesign) {
    return CircleOneCompatibilityEnum.ONEDESIGN;
  }
  return CircleOneCompatibilityEnum.NONE;
};

export const circleOneCompatibilitiesRadioItems: RadioItem[] = Object.keys(
  CircleOneCompatibilityEnum
).map((value) => {
  return {
    label: i18next.t(`products.productForm.circleOneCompatibility.${value.toLowerCase()}`, {
      ns: 'catalog'
    }),
    value: value
  };
});

export const languages = Object.keys(Language).map((value) => {
  return { label: i18next.t(`language.${value}`, { ns: 'common' }), value: value };
});

export const viteMode = (): string => {
  let mode = '';
  if (import.meta.env.MODE === 'development' && import.meta.env.VITE_GOOGLE_CLOUD_DEV) {
    mode = 'dev';
  } else if (import.meta.env.MODE === 'development') {
    mode = 'local';
  }
  return mode;
};

export const isOrdersApi = (urlStartPart: string): boolean => {
  return ['orders', 'common-types', 'components', 'products', 'labs'].includes(urlStartPart);
};

export const isUsersApi = (urlStartPart: string): boolean => {
  return ['users', 'establishments'].includes(urlStartPart);
};

export const isManuApi = (urlStartPart: string): boolean => {
  return ['manufacturing'].includes(urlStartPart);
};

export const checkTokenValidity = (dispatch: Dispatch<any>) => {
  /**
   * Check if the current token is currently valid.
   * Otherwise, redirect the user to the login page.
   */
  forceRefreshToken().catch((err) => {
    if (err?.message.includes('auth/user-token-expired')) {
      dispatch(
        feedbackActions.setToast({
          message: i18next.t('auth/user-token-expired', { ns: 'error' }),
          type: ToastType.DANGER
        })
      );
    } else {
      dispatch(feedbackActions.setToast({ message: err.message, type: ToastType.DANGER }));
    }
  });
};
